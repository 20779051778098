<template>
  <div class="w100p">
    <div class="flex-a-b-c h70 pr20 mb20">
      <div></div>
      <div class="d-flex">
        <el-button size="small" type="primary" @click="initialize_tap">
          恢复默认
        </el-button>
        <el-button size="small" type="primary" @click="save_template">
          保存
        </el-button>
      </div>
    </div>
    <div class="w100p d-flex">

      <!-- left -->
      <div class="w50p flex-c-c pt30 pb30">
        <div class="phoneClass borderboxd-flex flex-column">
          <div class="d-flex oyauto imgScroll" style="height:650px;width:100%;">
            <div class="felx1 text-align">
              <p class="fs10 " style="line-height: 40px;">商城</p>
              <!-- 商城模板 -->
              <div class="d-flex flex-column bgred brtop20">
                <div class="" v-for="(moduleList,index) in ecommerceList" :key="index">
                  <!-- 轮播 -->
                  <div class="borderbox w100p h150 mb18 posre festivalBanner"
                    :style="{backgroundImage: 'url(' +  moduleList.list[0].img +  ')'}"
                    v-if="moduleList.type == 'banner'" @click="selected_tap(moduleList.type)">
                    <div class="flex-c-c flex-column h100p pt40">
                      <p class="cfff fs12 fwbold lineheight1 mb10">{{moduleList.list[0].first}}</p>
                      <p class="cfff fs7 lineheight1">{{moduleList.list[0].second}}</p>
                    </div>
                    <div class="posab control" @click.stop :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' ">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                    </div>
                  </div>

                  <!-- 优惠券 -->
                  <div class="borderbox w100p h114 mb18 posre festivalBanner"
                    :style="{backgroundImage: 'url(' +  moduleList.list[0].img +  ')'}"
                    v-if="moduleList.type == 'coupon'" @click="selected_tap(moduleList.type)">
                    <div class="flex-a-c flex-column ">
                      <div class="w235 h26 couponBg1 flex-c-c fs7 lineheight0 cFDE2BB fwbold mb10 ">
                        {{moduleList.title}}
                      </div>
                      <div class="d-flex ml10 mr10 bgfff pa5 br10 text-align-left">
                        <div class="couponBg2" v-for="(item,k) in moduleList.list" :key="k">
                          <p class="cred fs6" v-if="item.couponType == 1">￥<span
                              class="fs12">{{item.couponFaceValue | discount}}</span></p>
                          <p class="cred fs12" v-if="item.couponType == 2">{{item.couponDiscount | discount}}<span
                              class="fs6"> 折</span></p>
                          <p class="fs6 cred">满{{item.minAmount}}元可用</p>
                        </div>
                      </div>
                    </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' ">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>
                    </div>
                  </div>


                  <!-- tab -->
                  <div class="w100p borderbox pl10 pr10 d-flex justify-content-between flex-wrap-y posre "
                    v-if="moduleList.type == 'tab'" @click="selected_tap(moduleList.type)">
                    <div class="d-flex flex-column bgfff mb10 br10" v-for="(item,k) in moduleList.list" :key="k">
                      <img :src="item.img" class="h54 w73">
                      <div class="bgfff fs7 pb5 brbottom10" :style="{color:item.color,background:item.bg}">
                        {{item.title}}</div>
                    </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' ">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                    </div>
                  </div>
                  <!-- top好货 -->
                  <div class="pl10 pr10 mt18 w100p borderbox posre" v-if="moduleList.type == 'seckill'"
                    @click="selected_tap(moduleList.type)">
                    <div class="w100p h45 br10 bgFDE2BB flex-a-b-c pl14 pr14 mb14">
                      <p class="fs9 fwbold cred">{{moduleList.left_title}}</p>
                      <div class="pl14 pr14 h26 br18 bgred flex-c-c cfff fs7">
                        {{moduleList.right_title}}
                      </div>
                    </div>
                    <div class="d-flex text-align-left mb17 ">
                      <div class="flex1 d-flex flex-wrap-y">
                        <div class="d-flex flex-column bgfff pall10 br10 mb14" :class="{'mr18':k%2 != 1} "
                          v-for="(item,k) in moduleList.list" :key="k">
                          <img :src="item.goodPhoto" class="w130 h130 br5 mb14">
                          <div class="d-flex flex-column align-items-center">
                            <div class="d-flex">
                              <p class="fs9 corange lineheight1 mr5">￥{{(item.minKillPrice/100) || 0}}</p>
                              <div class="d-flex align-items-end pb2 text-decoration fs6 cA3 lineheight1">
                                ￥{{(item.maxPrice/100) || 0}}</div>
                            </div>
                            <p class="cA1 fs7 fwbold pt2 mb10">{{item.goodsName}}</p>
                            <div class="w80 h23 br18 bgred flex-c-c cfff fs7">
                              立即购买
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' ">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                    </div>
                  </div>
                  <!-- new大牌 -->
                  <div class="pl10 pr10 mt18 w100p borderbox posre" v-if="moduleList.type == 'boutique'"
                    @click="selected_tap(moduleList.type)">
                    <div class="w100p h45 br10 bgFDE2BB flex-a-b-c pl14 pr14 mb14">
                      <p class="fs9 fwbold cred">{{moduleList.left_title}}</p>
                      <div class="pl14 pr14 h26 br18 bgred flex-c-c cfff fs7">
                        {{moduleList.right_title}}
                      </div>
                    </div>
                    <div class="d-flex text-align-left mb17 ">
                      <div class="flex1">
                        <div class="d-flex justify-content-between h118 bgfff pall10 pl114 br10 mb18 posre ml10"
                          :class="{'mt10': k == 0}" v-for="(item,k) in moduleList.list" :key="k">

                          <div class="d-flex flex-column justify-content-between">
                            <div>
                              <p class="cA1 fs8">{{item.goodsName}}</p>
                              <p class="cA3 fs6">{{item.tips}}</p>
                            </div>
                            <p class="cred fs9">
                              ￥{{(item.price/100)}}
                            </p>
                          </div>
                          <img :src="item.goodPhoto" class="newdapai">

                        </div>
                      </div>
                    </div>
                    <div class="posab control" :class="moduleList.type == currentType ? 'fade_in' : 'fade_out' ">
                      <i class="el-icon-caret-top cfff" @click.stop="templateUp(index)"></i>
                      <i class="el-icon-caret-bottom cfff" @click.stop="templateDown(index)"></i>
                      <i class="el-icon-delete cfff" @click.stop="templateDelete(index)"></i>

                    </div>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div class="d-flex flex1 bgfff justify-content-between">
            <div style="width:52px;height:100%" class="flex-c-c flex-column" v-for="(item,index) in tabList"
              :key="index">
              <img :src="index == 2 ? item.selectedIconPath : item.iconPath " class="w40 h40">
              <p class="fs6">{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
      <!-- right -->
      <div class="w50p posre d-flex oyauto oscrollbar">
        <div class="flex1 h550 pt20 pl20 pb20 w100p">
          <div class="mt20" v-for="(moduleList,index) in ecommerceList" :key="index">
            <!-- 促销 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-if="currentType == 'banner' && moduleList.type == currentType">
              <p class="textc fs9 mb20 fwbold">轮播图</p>
              <div class="bgf7fa pa10 d-flex align-items-center  posre " v-for="(item,k) in moduleList.list" :key="k">
                <div class="d-flex flex-column align-items-center ">
                  <el-upload accept="image/jpeg,image/png" class="addTabIcon" :action="uploadUrl" :show-file-list="false"
                    :on-success="bannerPicSuccess.bind(null, {'type':currentType,'index':k,'data':item})"
                    :before-upload="beforeUploadImageDynamicPic">
                    <img v-if="item.img" :src="item.img" class="avatar dynamic ">
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <p class="fs6">建议上传750px * 324px的图片效果最佳</p>
                </div>
                <jumpSet title="123123123" :index="currentIndex" :currentSelect="k" @currentSelect_tap="currentSelect_tap" :goodsData="item"
                  @select_tap="select_tap" :currentGoods="item"></jumpSet>

              </div>
              <div class="bgf7fa pl10 pr10 pb20">
                <p class="fs8 mb10">
                  第一行文案：
                </p>
                <el-input :placeholder="moduleList.list[0].first" class="mb20" maxlength="6" show-word-limit
                  v-model="moduleList.list[0].first"></el-input>
                <p class="fs8 mb10">
                  第二行文案
                </p>
                <el-input :placeholder="moduleList.list[0].second" maxlength="6" show-word-limit
                  v-model="moduleList.list[0].second"></el-input>
              </div>
            </div>
            <!-- 优惠券 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-if="currentType == 'coupon' && moduleList.type == currentType">
              <coupon :listData="moduleList" @delete_seckill="delete_seckill" @add_seckill="add_seckill"
                @confirmSeckill="confirmSeckill"></coupon>
            </div>

            <!-- tab -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'tab' && moduleList.type == currentType">
              <tab :listData="moduleList" @delete_seckill="delete_seckill" @add_seckill="add_seckill" :size="'160*120'"
                @confirmSeckill="confirmSeckill" @reset_tab_tap="reset_tab_tap">

              </tab>

            </div>
            <!-- 秒杀 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'seckill' && moduleList.type == currentType">
              <specialGoods :listData="moduleList" type="seckill" @delete_seckill="delete_seckill"
                @add_seckill="add_seckill" @confirmSeckill="confirmSeckill">

              </specialGoods>
            </div>
          
            <!-- 精选商品 -->
            <div class="pt20 pb10 pl10 pr10 pr10 br10 shadow-gray50 w500"
              v-else-if="currentType == 'boutique' && moduleList.type == currentType">
              <specialGoods :listData="moduleList" type="isOrdinary" @delete_seckill="delete_seckill" :showTips="true"
                @add_seckill="add_seckill" @confirmSeckill="confirmSeckill">
              </specialGoods>
            </div>

          </div>
        </div>
        


      </div>

    </div>
  </div>
</template>

<script>
  import {
    saveShopModelConfig,
    queryShopModelConfig,
    updateShopModelConfig
  } from "@/api/wechat";
  import {
    saveTabMenu,
    queryTabMenu
  } from "@/api/wechat";
  import { getDataTimeSec } from "@/utils";
  import jumpSet from "./components/jumpSet";
  import specialGoods from './festival/specialGoods'
  import coupon from './festival/coupon'
  import tab from './ecommerce/tab'


  export default {
    data() {
      return {
        uploadUrl: this.$store.state.uploadingUrl,
        ossUrl: this.$store.state.ossUrl,
        isUpdate: false,
        tabList: [
          {
            text: "名片",
            pagePath: "pages/index/main",
            iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card.png",
            selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/card_select.png"
          },
          {
            text: "媒体",
            pagePath: "pages/media/main",
            iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video.png",
            selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/video_select.png"
          },
          {
            text: "商城",
            pagePath: "pages/Products/main",
            iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_gray.png",
            selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/prod_select.png"
          },
          {
            text: "动态",
            pagePath: "pages/Dynamic/main",
            iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/browser.png",
            selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/browser_select.png"
          },
          {
            text: "官网",
            pagePath: "pages/WebSite/main",
            iconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer.png",
            selectedIconPath: this.$store.state.ossUrl + "yimai_photos/tabbarIcon/computer_select.png"
          },
        ],
        ecommerceList: [{
          type: 'banner',
          list: [{
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/bg1.png',
            first: '618',
            second: '年中促销',
            page:'',
            goods:''
          }]
        }, {
          list: [{
            minAmount: "100",//滿
            couponFaceValue: 10,//减
            name:"满减卷",
            couponType: 1,
            grantStartTime:'2021-07-01',
            grantEndTime:'2021-12-01'
          }, {
            minAmount: "200",//滿
            couponFaceValue: 30,//减
            name:"满减卷",
            couponType: 1,
            grantStartTime:'2021-07-01',
            grantEndTime:'2021-12-01'
          }, {
            minAmount: "500",//滿
            name:"满减卷",
            couponDiscount: 9,//减
            couponType: 2,
            grantStartTime:'2021-07-01',
            grantEndTime:'2021-12-01'
          }],
          title: '优惠好礼、送完即止',
          type: 'coupon'
        }, {
          list: [{
            img:'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab1.png',
            bg: "linear-gradient(90deg, rgba(253, 202, 201, 0.2), #FDCAC9",
            color: "#ED1C36",
            title: '上衣外套'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab2.png',
            bg: "linear-gradient(90deg, rgba(251, 224, 193, 0.2), #FBE0C1",
            color: "#ED6335",
            title: '精品包包'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab3.png',
            bg: "linear-gradient(90deg, rgba(213, 223, 252, 0.2), #D5DFFC)",
            color: "#3859E9",
            title: '潮流服饰'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab4.png',
            bg: "linear-gradient(90deg, rgba(232, 211, 253, 0.2), #E8D3FD)",
            color: "#7C3AD8",
            title: '珠宝首饰'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab5.png',
            bg: "linear-gradient(90deg, rgba(232, 211, 253, 0.2), #E8D3FD)",
            color: "#7C3AD8",
            title: '办公家具'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab6.png',
            bg: "linear-gradient(90deg, rgba(213, 223, 252, 0.2), #D5DFFC)",
            color: "#3859E9",
            title: '美食美酒'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab7.png',
            bg: "linear-gradient(90deg, rgba(251, 224, 193, 0.2), #FBE0C1",
            color: "#ED6335",
            title: '图书文教'
          }, {
            img: 'https://xcd-stand.oss-cn-beijing.aliyuncs.com/yimai_photos/festival/tab8.png',
            bg: "linear-gradient(90deg, rgba(253, 202, 201, 0.2), #FDCAC9",
            color: "#ED1C36",
            title: '美妆护肤'
          }],

          type: 'tab'
        }, {
          type: 'seckill',
          left_title: 'TOP好货',
          right_title: '好货促销.爆款直降',
          list: [
            {
              goodsName: '潮流大衣',
              goodsId: '',
              goodPhoto: require('../../../../src/static/festival/top1.png'),
              minKillPrice: '9990',
              maxPrice: '888800'
            },
            {
              goodsName: '时尚帽子',
              goodsId: '',
              goodPhoto: require('../../../../src/static/festival/top2.png'),
              minKillPrice: '9990',
              maxPrice: '888800'
            },
            {
              goodsName: '潮流短袖',
              goodsId: '',
              goodPhoto: require('../../../../src/static/festival/top3.png'),
              minKillPrice: '9990',
              maxPrice: '888800'
            },
            {
              goodsName: '登山鞋',
              goodsId: '',
              goodPhoto: require('../../../../src/static/festival/top4.png'),
              minKillPrice: '9990',
              maxPrice: '888800'
            }
          ]
        }, {
          type: 'boutique',
          left_title: 'NEW大牌',
          right_title: '年中促销·好物大赏',
          list: [
            {
              goodsName: '华为手机',
              goodPhoto: require('../../../../src/static/festival/new1.png'),
              tips: '快，更快',
              price: '888800',
              maxPrice: '888800'
            },
            {
              goodsName: '冰箱',
              tips: '超强制冷',
              goodPhoto: require('../../../../src/static/festival/new2.png'),
              price: '88800',
              maxPrice: '888800'
            },
            {
              goodsName: '泰国香米',
              tips: '好吃不贵',
              goodPhoto: require('../../../../src/static/festival/new3.png'),
              price: '88800',
              maxPrice: '888800'
            }
          ]
        }],
        currentType: '',
        currentIndex: 0,
        currentSelect: '',
        initializeData: "",
        requestWay:'save'
      };
    },
    components: {
      jumpSet,
      specialGoods,
      tab,
      coupon
    },
    created() {
      this.initializeData = JSON.parse(JSON.stringify(this.ecommerceList))
      console.log(this.initializeData)
      this.queryShopConfig()
    },
    filters:{
      discount(a){
        let num = 1
        var a_type = typeof(a);
        if(a_type == "number"){
            var aStr = a.toString();
            var aArr = aStr.split('.');
        }else if(a_type == "string"){
            var aArr = a.split('.');
        }
        
        if(aArr.length > 1) {
            a = aArr[0] + "." + aArr[1].substr(0, num);
        }
        return a
      }
    },
    mounted() {

    },
    methods: {
      queryShopConfig(){
        let data = {
          modelEnum:'holiday_offer',
        }
        queryShopModelConfig(data)
        .then(res=>{
          console.log(res)
          if(res.data && res.data.modelId){
            this.requestWay = 'up'
            this.ecommerceList = JSON.parse(res.data.shopDetails[0].content)
          }
        })
      },
      selected_tap(type) {
        let index = ''
        this.ecommerceList.forEach((item, k) => {
          if (item.type == type) {
            this.currentIndex = k
          }
        })
        console.log(this.currentIndex)
        this.currentType = type
      },
      bannerPicSuccess(obj, res, file) {
        //动态图上传成功
        let than = this
        console.log(obj)
        this.ecommerceList.forEach((item, k) => {
          if (item.type == obj.type) {

            than.$set(than.ecommerceList[k].list[obj.index], 'img', this.ossUrl + '/' + res.data)
          }
        })
      },
      // 上移
      templateUp() {
        let arr = this.ecommerceList,
          index = this.currentIndex
        if (index !== 0) {
          this.swapArray(arr, this.currentIndex, --this.currentIndex)
        } else {
          this.$message.error('已经处于置顶，无法上移')
        }
      },
      templateDown() {
        let arr = this.ecommerceList,
          length = this.ecommerceList.length,
          index = this.currentIndex
        if (index + 1 != length) {
          this.swapArray(arr, this.currentIndex, ++this.currentIndex)
        } else {
          this.$message.error('已经触底，无法下移')
        }
      },
      templateDelete() {
        let index = this.currentIndex
        this.ecommerceList.splice(index, 1)
      },
      swapArray(arr, index1, index2) {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0];
        return arr;
      },
      beforeUploadImageDynamicPic(file) {
        var _this = this;
        var isLt10M = file.size / 1024 / 1024 < 10;
        if (['image/jpeg', 'image/png'].indexOf(file.type) == -1) {
          _this.$message.error('请上传正确的图片格式');
          return false;
        }
        if (!isLt10M) {
          _this.$message.error('上传图片大小不能超过10MB哦!');
          return false;
        }
      },
      currentSelect_tap(index) {
        this.currentSelect = index
      },
      select_tap(index,val,type,goods,currentSelect){
        if(goods){
          if(currentSelect || currentSelect === 0){
            this.ecommerceList[this.currentIndex].list[currentSelect].goods = goods
            delete this.ecommerceList[this.currentIndex].list[currentSelect].page
          }else{
            this.ecommerceList[this.currentIndex].list[this.currentSelect].goods = goods
            delete this.ecommerceList[this.currentIndex].list[this.currentSelect].page
          }
          
        }else{
          if(type){
            if(currentSelect || currentSelect === 0){
              this.ecommerceList[this.currentIndex].list[currentSelect].page = type
              delete this.ecommerceList[this.currentIndex].list[currentSelect].goods
            }else{
              this.ecommerceList[this.currentIndex].list[this.currentSelect].page = type
              delete this.ecommerceList[this.currentIndex].list[this.currentSelect].goods
            }
          }
          
        }
      },
      delete_seckill(index, type) {
        let than = this
        than.ecommerceList.forEach((item, k) => {

          if (item.type == type) {
            than.ecommerceList[k].list.splice(index, 1)
          }
        })
      },
      add_seckill(type) {
        let than = this
        than.ecommerceList.forEach((item, k) => {
          if (item.type == type) {
            console.log(than.ecommerceList[k].list, 'listdata')
            than.ecommerceList[k].list.push({
              title: '',
              newPrice: '',
            })
          }
        })
        console.log(than.ecommerceList)
      },
      //重置数据
      initialize_tap() {
        this.ecommerceList = JSON.parse(JSON.stringify(this.initializeData))
      },
      //保存模板
      save_template() {
        let than = this
        let arr = JSON.parse(JSON.stringify(this.ecommerceList))
          
        let p = new Promise((resolve,reject) => {
          arr.forEach((item,k)=>{
            if(item.list){
              item.list.forEach((v,k)=>{
                if(item.type == 'coupon'){
                  if(!v.couponId){
                     reject("优惠券未设置")
                  }
                }
                if(item.type == 'seckill'){
                  if(!v.goodsId){
                    reject("秒杀模块未设置商品")
                  }
                }
                if(item.type == 'boutique'){
                  if(!v.goodsId){
                    reject("精选模块未设置商品")
                  }
                }

              })
            }
          })
          resolve('succ')
        })
        p.then(res => {
          console.log(res,'22222');
          let data = {
            modelEnum:'holiday_offer',
            shopDetails:[{
              content:JSON.stringify(arr)
            }]
          }
          if(than.requestWay == 'save'){
            saveShopModelConfig(data)
            .then(res=>{
              if(res.code == 200){
                than.$message.success("保存成功")
                setTimeout(()=>{
                  than.queryShopConfig()
                  than.$router.push('mallSetting')
                },500)
              }
            })
            .catch(err => {
              if(err.code == 201){
                this.$message.error(err.message)
              }
              console.log(err,'wwwww');
            })
          }else{
            updateShopModelConfig(data)
            .then(res=>{
              if(res.code == 200){
                than.$message.success("保存成功")
                setTimeout(()=>{
                  than.queryShopConfig()
                  than.$router.push('mallSetting')
                },500)
              }
            })
            .catch(err => {
              if(err.code == 201){
                this.$message.error(err.message)
              }
              console.log(err,'wwwww');
            })
          }
        })
        .catch(err => {
          this.$message.error(err)
        })

      },
      //重置tab
      reset_tab_tap(data) {
        let than = this
        than.ecommerceList.forEach((item, k) => {
          if (item.type == 'tab') {
            than.$set(than.ecommerceList, k, data)
          }
        })
      },
      //确认秒杀商品
      confirmSeckill(index, data, type) {
        console.log(index, data, type)
        let than = this
        than.ecommerceList.forEach((item, k) => {
          if (item.type == type) {
            than.ecommerceList[k].list[index] = data
            than.$set(than.ecommerceList[k].list, index, data)
          }
        })

      }
    }
  };
</script>

<style lang="scss" scoped>
  .phoneClass {
    padding: 50px 30px 0px 30px;
    background-image: url('../../../assets/images/iphonex.png');
    width: 400px;
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  .imgScroll::-webkit-scrollbar {
    width: 0 !important
  }

  .group_title {
    background-image: url('../../../static/ecommerce/bg1.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .group_bgcolor {
    background: linear-gradient(0deg, rgba(253, 78, 86, 0.3), #FD4E56);
  }

  .purchase {
    width: 74px;
    height: 32px;
    background: #FFA133;
    color: #fff;
    border-radius: 16px 0px 0px 16px;
    right: 0px;
    top: 45px;
    display: flex;
    font-weight: bold;
    align-items: center;
    padding-left: 11px;
    font-size: 16px;
  }

  .choicenessBgImg {
    bottom: 0px;
  }

  .choicenessBox {
    padding: 18px;
    width: 100p;
    background: #FFFFFF;
    box-shadow: 0px 2px 7px 0px rgba(56, 56, 56, 0.1);
    border-radius: 9px;
    display: flex;
  }

  .fade_in {
    opacity: 1;
    z-index: 99;
    transition: all 0.2s;
  }

  .fade_out {
    opacity: 0;
    display: none !important;
    transition: all 0.2s;
  }

  .control {
    right: 0px;
    top: 0px;
    width: 25px;
    height: 150px;
    background: #51CDCB;
    z-index: 99;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    font-size: 18px;
  }

  /deep/.addTabIcon .el-upload--text {
    width: 210px;
    height: 100px;
    line-height: 100px;
    margin-bottom: 10px;
  }

  /deep/ .addTabIcon .el-upload-list__item {
    width: 210px;
    height: 100px;
    line-height: 100px;
  }

  .avatar {
    width: 100%;
    height: 100%;
  }

  .festivalBanner {
    background-size: 100% 100%;
  }

  .couponBg1 {
    background-image: url('../../../static/festival/bg2.png');
    background-size: 100% 100%;
  }

  .couponBg2 {
    width: 100px;
    height: 68px;
    box-sizing: border-box;
    background-image: url('../../../static/festival/coupon.png');
    background-size: 100% 100%;
    padding-left: 10px;
    padding-top: 10px;
  }

  .newdapai {
    position: absolute;
    width: 113px;
    height: 113px;
    border-radius: 9px;
    top: -10px;
    left: -10px;
  }
</style>